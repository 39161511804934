import React, { useState, useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import { SoundContextProvider } from "./context";
import "react-toastify/dist/ReactToastify.css";

// import Home from "./pages/home";
// import Bio from "./pages/bio";
// import Passion from "./pages/passion";
// import Ping from "./pages/ping";
// import Resume from "./pages/resume";
// import Works from "./pages/project";
// import PageNotFound from "./pages/404";

import LoadingC from "./components/mainLoader";
import MsgToast from "./components/ToastSoundMsg";
import MobileNav from "./components/mobile-nav";

import "./styles/App.scss";

const Home = lazy(() => import("./pages/home"));
const Bio = lazy(() => import("./pages/bio"));
const Passion = lazy(() => import("./pages/passion"));
const Ping = lazy(() => import("./pages/ping"));
const Resume = lazy(() => import("./pages/resume"));
const Works = lazy(() => import("./pages/project"));
const PageNotFound = lazy(() => import("./pages/404"));

const App = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [counterLoad, setCounterLoad] = useState(false);

  window.onload = function () {
    setCounterLoad(true);
    setTimeout(() => {
      setIsPageLoading(false);
    }, 2000);
  };

  useEffect(() => {
    if (!isPageLoading) {
      if (window.innerWidth <= 500) {
        setTimeout(() => {
          toast(<MsgToast expMessage />);
        }, 2000);
      } else {
        setTimeout(() => {
          toast(<MsgToast />);
        }, 2000);
      }
    }
  }, [isPageLoading]);

  return (
    <Router>
      <SoundContextProvider>
        <div className="App">
          {isPageLoading && <LoadingC loaded={counterLoad} />}
          <Suspense fallback={<LoadingC loaded={true} />}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={Bio} />
              <Route exact path="/contact" component={Ping} />
              <Route exact path="/project" component={Works} />
              <Route exact path="/resume" component={Resume} />
              <Route path="/passion" component={Passion} />
              <Route render={() => <PageNotFound />} />
            </Switch>
          </Suspense>
          <MobileNav />
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          transition={Slide}
        />
      </SoundContextProvider>
    </Router>
  );
};

export default App;
