import React from 'react';
import Loader from './../loader';
import './style.scss';

const MainLoader = ({loaded}) => {
  
  return (
    <div className="page-loader-container">
      <div className={`page-loader ${loaded ? 'fade-out' : ''}`}>
        <div className="loader">
          <Loader />
          <div className="tubelight">
            <span>L</span>
            <span>O</span>
            <span>a</span>
            <span>d</span>
            <span>i</span>
            <span>n</span>
            <span>g</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainLoader
