import React from "react";
import { Link, withRouter } from "react-router-dom";

import { ReactComponent as BioIcon } from "../../assets/icons/mobile-nav/bio.svg";
import { ReactComponent as ContactIcon } from "../../assets/icons/mobile-nav/contact.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/mobile-nav/home.svg";
import { ReactComponent as ProjectIcon } from "../../assets/icons/mobile-nav/project.svg";
import { ReactComponent as ResumeIcon } from "../../assets/icons/mobile-nav/resume.svg";
import "./styles.scss";

const MobileNav = ({ history }) => {
  const isSelected = name =>
    history.location.pathname.includes(name) ? "nav-link selected" : "nav-link";

  return (
    <div className={`mobile-nav-container`}>
      <div className="mobile-nav-links">
        <Link to="/about" className={isSelected("about")}>
          <span className="nav-link-img">
            <BioIcon />
          </span>
          <div className="nav-link-text">Bio</div>
        </Link>

        <Link to="/project" className={isSelected("project")}>
          <span className="nav-link-img">
            <ProjectIcon />
          </span>
          <div className="nav-link-text">Projects</div>
        </Link>

        <Link to="/" className="nav-link">
          <span className="nav-link-img">
            <HomeIcon />
          </span>
          <div className="nav-link-text">Home</div>
        </Link>

        <Link to="/resume" className={isSelected("resume")}>
          <span className="nav-link-img">
            <ResumeIcon />
          </span>
          <div className="nav-link-text">Resume</div>
        </Link>

        <Link to="/contact" className={isSelected("contact")}>
          <span className="nav-link-img">
            <ContactIcon />
          </span>
          <div className="nav-link-text">Contact</div>
        </Link>
      </div>
    </div>
  );
};

export default withRouter(MobileNav);
