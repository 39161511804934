import React, { createContext, useState } from "react";

const SoundContext = createContext(true);

const SoundContextProvider = ({ children }) => {
  const [soundEnabled, setSoundEnabled] = useState(false);
  const toggleSound = () => setSoundEnabled(soundEnabled => !soundEnabled);

  return (
    <SoundContext.Provider
      value={{
        soundEnabled: soundEnabled,
        toggleSound: toggleSound
      }}
    >
      {children}
    </SoundContext.Provider>
  );
};

export { SoundContext, SoundContextProvider };
