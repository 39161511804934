import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Loader = ({container}) => {
  return (
    <div className={`loader ${container ? 'container': ''}`}>
      <div className="sick-container">
        <div className="core"></div>

        <div className="core-inner-spin">
          <div className="core-inner-arc core-inner-arc_start-a"></div>
          <div className="core-inner-arc core-inner-arc_end-a"></div>

          <div className="core-inner-arc core-inner-arc_start-b"></div>
          <div className="core-inner-arc core-inner-arc_end-b"></div>

          <div className="core-inner-circle-a"></div>
          <div className="core-inner-circle-b"></div>
        </div>

        <div className="core-outer-spin">
          <div className="core-outer-arc core-outer-arc_start-a"></div>
          <div className="core-outer-arc core-outer-arc_end-a"></div>

          <div className="core-outer-arc core-outer-arc_start-b"></div>
          <div className="core-outer-arc core-outer-arc_end-b"></div>

          <div className="core-outer-circle-a"></div>
          <div className="core-outer-circle-b"></div>
        </div>
      </div>
    </div>
  )
}

Loader.propTypes = {
  container: PropTypes.bool,
}
Loader.defaultProps = {
  container: true,
}

export default Loader;
