import React, { Fragment, useContext } from "react";
import { SoundContext } from "../../context";

const MsgToast = ({ expMessage }) => {
  const { toggleSound, soundEnabled } = useContext(SoundContext);
  return expMessage ? (
    <p>
      For Best view and experince, please view in <b>Desktop</b> <i>OR</i> in{" "}
      <b>landscape</b> mode
    </p>
  ) : (
    <Fragment>
      <p>
        If you wish to disable the sound click here{" "}
        <i>
          <b>OR</b>
        </i>{" "}
        later from the side menu
      </p>
      <button className="btn" onClick={toggleSound}>
        {soundEnabled ? "Disable" : "Enable"} Sound
      </button>
    </Fragment>
  );
};

export default MsgToast;
